.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-chatapp-frame-content {
  display: block;
  border: none;
  position: fixed;
  inset: auto 0px 0px auto;
  width: 100%;
  height: 647px;
  max-height: 100%;
  opacity: 1;
  color-scheme: none;
  background: none transparent;
  margin: 0px;
  max-width: 100vw;
  transform: translateY(0px);
  transition: none 0s ease 0s !important;
  visibility: visible;
  z-index: 999999999 !important;
}
#custom-chatapp-chat-button {
  position: fixed;
  width: 112px;
  height: 140px;
  bottom: 12px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
  right: 0px;
}

#custom-chatapp-chat-button:not(.sidebar) .buttonWave {
  position: absolute;
  z-index: -1;
  width: 60px;
  height: 60px;
}
#custom-chatapp-chat-button:not(.sidebar).custom-chatapp-clicked .buttonWave::after {
    animation: 0.5s ease-out 0s 1 normal none running buttonWave;
}
#custom-chatapp-chat-button:not(.sidebar) .buttonWave::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgb(20, 127, 255);
    opacity: 0.5;
    right:0;
}
#custom-chatapp-button-body {
  width: 60px;
  height: 60px;
  border-radius: 28px;
  display: inherit;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  pointer-events: initial;
  background-size: 130% 130%;
  transition: all 0.2s ease-in-out 0s;
  position: relative;
}
#custom-chatapp-button-body::before {
    content: "";
    transition: opacity 0.5s ease-in-out 0s;
    position: absolute;
    inset: -1px;
    opacity: 0;
    border-radius: 50%;
    background-color: rgb(198, 204, 220);
}
#custom-chatapp-chat-button button i.for-closed.active {
  transform: translateX(0px);
}
#custom-chatapp-chat-button button i.for-closed {
  transform: translateX(-10px);
}
#custom-chatapp-chat-button button i.active {
  opacity: 1;
}
#custom-chatapp-chat-button button i {
  height: 26px;
  width: 26px;
  position: absolute;
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  /* justify-content: center; */
}
i {
  user-select: none;
}
#custom-chatapp-chat-button button i.type1 svg {
  fill: currentcolor;
}
svg {
  width: 24px;
  height: 24px;
}
#custom-chatapp-chat-button button i.type1::after {
  content: "";
  position: absolute;
  width: 68px;
  height: 68px;
  border-radius: 32px;
  background: rgb(255, 255, 255);
  transition: all 0.2s ease-in-out 0s;
  transform: scale(0);
  right: -18px;
}
#custom-chatapp-chat-button button i.for-closed.active {
  transform: translateX(0px);
}
#custom-chatapp-chat-button button i.for-closed {
  transform: translateX(-10px);
}
#custom-chatapp-chat-button button i.type2 {
  width: 32px;
  height: 32px;
}
#button button i.type1.for-opened {
  width: 31px;
  height: 28px;
}
#custom-chatapp-chat-button button i.for-opened {
  transform: translateX(10px);
}
button, button.material-icons {
  background: none;
  border: 0px;
  color: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0px;
  user-select: none;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


#custom-chatapp-chat-button button i.type2 svg {
  width: 32px;
  height: 32px;
  fill: rgb(0, 125, 252);
  transform: scale(0);
}

svg:not(:root) {
  overflow-clip-margin: content-box;
  overflow: hidden;
}

#custom-chatapp-button-body {
  background: linear-gradient(135deg, rgb(254, 91, 52,1), rgb(255, 123, 0,1));
  box-shadow: rgba(255, 107, 0, 0.5) 0px 4px 24px;
}

body:not(.mobile) #button button:not(.disabled):hover i.type1 svg, body:not(.mobile) #button button:not(.disabled):focus i.type1 svg {
  transform: scale(1.4);
}

body:not(.mobile) #button button:not(.disabled):hover i.type1::after, body:not(.mobile) #button button:not(.disabled):focus i.type1::after {
  transform: scale(1);
}

#custom-chatapp-chat-button button i.for-closed.active {
  transform: translateX(0px);
}

.custom-chatapp-chat.chrome, .start-group.chrome {
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
}

.custom-chatapp-chat {
  max-height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
}

.custom-chatapp-chat, .start-group {
  width: 390px;
  position: fixed;
  bottom: 26px;
  border-radius: 16px;
  pointer-events: auto;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 22px 0px;
  overflow: hidden;
  z-index: 999;
  right: 35px;
  left: auto;
  background-color: white;
}
.header-image{
  padding-top: 10px;
  
}
.message-operator.bots-quick-replies {
  width: 85%;
  background-color: rgb(255, 255, 255);
  margin-top: 0px;
  float: right;
}

  .message-operator.bots-quick-replies .button-wrapper {
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: end;
      justify-content: flex-end;
      width: 100%;
      border: none;
  }

.message-operator {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  float: left;
}

.message {
  padding: 10px 16px;
  border-radius: 20px 20px 20px 0;
  margin: 2px 0px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 85%;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
}

.custom-chatapp-input-group {
  padding: 0px 15px 0px;
  position: relative;
  background: rgb(255, 255, 255);
  z-index: 3;
  flex: 0 0 auto;
  border-top: 1px solid #dbdfe6;
}

#custom-chatapp-chat-input {
  border: 0px;
  width: 100%;
  font-size: 17px;
  margin: 5px 0px;
  resize: none;
  line-height: 30px;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  font-size: 15px;
  color: gray;
}

#custom-chatapp-chat-input:focus-visible {
  outline: none;
}

.custom-chatapp-send-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26px;
  flex: 0 0 26px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
  color: #fe5b34;
}

#custom-chatapp-conversation-group {
  padding: 5px 15px;
  height: 357px;
  overflow: hidden auto;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  max-height: 357px;
  min-height: 160px;
  flex: 0 1 auto;
}
#custom-chatapp-conversation-group::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
#custom-chatapp-conversation-group::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}
#custom-chatapp-conversation-group::-webkit-scrollbar-thumb
{
	background-color: #a2a2a2;
	border: 2px solid #a2a2a2;
}
.message-operator.bots-quick-replies button {
  font-size: 15px;
  padding: 8px 16px;
  border: 1px solid;
  border-radius: 20px;
  margin: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: inherit;
}

.custom-chatapp-offline-message span.online::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: calc(50% - 4px);
  background: rgb(88, 183, 67);
  border-radius: 50%;
  left: 0px;
}

.custom-chatapp-offline-message span {
  z-index: 2;
  position: relative;
  display: inline-block;
  font-size: 13px;
}

  .message-operator.message-with-buttons .button-wrapper, .message-operator .message-with-buttons .button-wrapper, .message-operator.bots-quick-replies .button-wrapper {
      background: rgb(255, 255, 255);
      width: 100%;
      margin-top: 10px;
     /* border-width: 0px 1px 1px;
      border-right-style: solid;
      border-bottom-style: solid;
      border-left-style: solid;
      border-right-color: rgb(235, 238, 240);
      border-bottom-color: rgb(235, 238, 240);
      border-left-color: rgb(235, 238, 240);*/
      border-image: initial;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-style: initial;
      border-top-color: initial;
      position: relative;
  }

#custom-chatapp-chat-button button i.for-opened.active {
  transform: translateX(0px);
}
.message-operator.bots-quick-replies .sent {
    font-size: 15px;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    margin: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: inherit;
}
.message-operator.message-with-buttons .sent, .message-operator .message-with-buttons .sent, .message-operator.bots-quick-replies .sent {
  margin: 0px auto;
  min-width: 100%;
  display: block;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 16px;
  color: rgb(0, 125, 252);
  background: #f2f9ff;
  position: relative;
  z-index: 2;
  outline: none;
  border-radius: 20px;
  font-weight:normal;
}
.custom-chatapp-chat-header {
  padding: 15px 15px 0px;
  background: linear-gradient(-61deg, rgb(255, 172, 152, 1), rgb(254, 91, 52, 1));
  position: relative;
  z-index: 4;
  flex: 0 0 auto;
}
.custom-chatapp-chat h2.oneline {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
}
.custom-chatapp-chat h2 {
  font-size: 18px;
  font-weight: 600;
  color: currentcolor;
  margin: 4px 0px 0px;
  padding: 0px;
  display: inline-block;
  position: relative;
  /*max-width: calc(100% - 50px);*/
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}
.custom-chatapp-chat h2 .emoji {
    width: 31px;
    height: 31px;
}
.custom-chatapp-chat h2 span {
  width: 145px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
button.material-icons.options, button.material-icons.exit-chat, label.material-icons.options, label.material-icons.exit-chat {
  z-index: unset;
}
button.material-icons, label.material-icons {
  position: absolute;
  z-index: 1;
  float: right;
  top: -4px;
}
button.material-icons.options::before, button.material-icons.exit-chat::before, label.material-icons.options::before, label.material-icons.exit-chat::before {
    background: rgba(0, 36, 92, 0.16);
}
button.material-icons::before, label.material-icons::before {
    content: "";
    position: absolute;
    background: rgb(239, 242, 246);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.16s ease-in-out 0s;
    transform: scale(0);
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}
button.material-icons svg#ic-minimize, button.material-icons svg.options-icon, label.material-icons svg#ic-minimize, label.material-icons svg.options-icon {
    fill: currentcolor;
}
.custom-chatapp-tidio-1s5t5ku span {
  background: rgb(255, 255, 255);
  padding: 6px 8px;
  border-radius: 2px;
  box-shadow: rgba(0, 18, 46, 0.32) 0px 2px 8px 0px;
  font-size: 13px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transition: all 0.16s ease-in-out 0s;
  z-index: 1;
  right: calc(100% + 10px);
  top: 50%;
  transform: translate(5px, -50%);
  color: rgb(6, 19, 43);
}
.custom-chatapp-offline-message {
  margin: 0 -28px 0px;
  color: currentcolor;
  width: calc(100% + 56px);
  padding: 0 28px 15px;
  position: relative;
  background-size: 100% calc(100% + 12px);
  z-index: 1;
  line-height: 20px;
}
.custom-chatapp-offline-message span.online {
  padding-left: 20px;
}
.no-clip-path .custom-chatapp-offline-message::after {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  bottom: -8px;
  left: -5px;
  border-image-source: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
  border-image-slice: 0 0 100%;
  border-image-width: 0 0 15px;
  border-image-repeat: stretch;
  border-width: 0px 0px 15px;
  border-bottom-style: solid;
  border-color: initial;
  border-top-style: initial;
  border-left-style: initial;
  border-right-style: initial;
}
.custom-chatapp-input-group .footer-input-wrapper, .custom-chatapp-input-group .footer-icons-wrapper {
  transition: all 0.5s ease-in-out 0s;
  opacity: 1;
  transform: translateY(0px);
}
.message-operator {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  float: left;
  margin-top: 10px;
}
.message span.message-content {
  white-space: pre-line;
}
#custom-chatapp-chatContainer {
  transition: all 0.3s ease-in-out;
  right:-300px;
 
}
.custom-chatapp-onlyBubble {
  display: none;
  transition: all 0.3s ease-in-out;
}
.open {
  display: block;
  transition: all 0.3s ease-in-out;
}
#custom-chatapp-chatContainer.open {
  right: 0;
}
.footer-input-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.custom-chatapp-clicked {
  display: none !important;
}
#button:not(.sidebar).custom-chatapp-clicked .buttonWave::after {
  animation: 0.5s ease-out 0s 1 normal none running buttonWave;
}
#button:not(.sidebar) .buttonWave::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(20, 127, 255);
  opacity: 0.5;
}
.custom-chatapp-sentText {
  font-size: 16px;
  line-height: 19px;
  padding: 8px 16px;
  color: rgb(0, 125, 252);
  background: #f2f9ff;
  position: relative;
  z-index: 2;
  outline: none;
  border-radius: 20px 20px 0 20px;
  font-weight: normal;
  text-align: right;
  max-width: 75%;
  margin-top: 5px;
}
.custom-chatapp-send-user-text{
  width: 100%;
  display: flex;
  /*flex-direction: column;*/
  justify-content: flex-end;
}
.custom-chatapp-send-bot-text{
  width: 100%;
  display: flex;
  margin-top: 10px;
}
.custom-chatapp-botText {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  padding: 10px 16px;
  border-radius: 20px 20px 20px 0;
  margin: 2px 0px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 75%;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
  font-weight: 400;
  text-align: left;
}
.svgsent svg path {
  fill: #1775ec;
}
.custom-chatapp-text-white {
  color: #fff !important;
}
.custom-chatapp-font-width-300{
  font-weight: 300 !important;
}
.custom-chatapp-font-width-400{
  font-weight: 400 !important;
}
.custom-chatapp-text-left{
  text-align: left !important;
}
.custom-chatapp-branding {
  background: #f0f2f5;
  color: #1c293b;
  font-size: 14px;
  padding: 5px 0;
  font-weight: 400;
}
.custom-chatapp-profile-image {
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.custom-chatapp-chat-icon {
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: -13px;
  margin-right: 10px;
}
#custom-chatapp-appointment-button{
  margin-top: 17px;
  margin-left: -15px;
}
#custom-chatapp-appointment-button.custom-chatapp-calendar{
  margin-left: -25px;
}
#custom-chatapp-img-body {
  border-radius: 28px;
  display: inherit;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  pointer-events: initial;
  background-size: 130% 130%;
  transition: all 0.2s ease-in-out 0s;
  position: relative;
}
.custom-chatapp-initial-message {
  pointer-events: initial;
  position: absolute;
  text-align: left;
  width: 250px;
  right: 35px;
  top: -35px;
  box-shadow: 0 0 2px 1px #dbd1d1;
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  cursor: pointer;
  background: #fff;
  display: inline-flex;
}
.custom-chatapp-initial-message .custom-chatapp-initial-head {
    font-size: 14px;
    margin: 0;
}
.custom-chatapp-initial-message .custom-chatapp-initial-head * {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
  display: inline-block;
}
.custom-chatapp-initial-message  p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}
.custom-chatapp-initial-message #custom-chatapp-minimize-button {
  top:-15px;
  padding: 5px;
  right: 0px;
}
.custom-chatapp-initial-message #custom-chatapp-minimize-button svg {
    height: 15px;
}
.custom-chatapp-send-icon:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.custom-chatapp-left #custom-chatapp-chat-button {
  left: 0;
}
.custom-chatapp-left .custom-chatapp-initial-message {
  left: 25px;
}
.custom-chatapp-left .custom-chatapp-chat {
  left: 25px;
}
.custom-chatapp-404{
  background-size: 100% !important;
  background-position: bottom !important;
}
.custom-chatapp-share-404 {
  background-size: 100% !important;
  background-position: bottom !important;
  height: 100%;
}
.custom-chatapp-chatbot-head {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  width:80%;
}
.custom-chatapp-chatbot-head-title {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.custom-chatapp-chatbot-head-title .custom-chatapp-font-width-300 {
  font-size: 14px;
}
#custom-chatapp-loader span{
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #3498db;
  margin: 0 3px;
}
#custom-chatapp-loader span:nth-child(1){
  animation: bounce 1s ease-in-out infinite;
}
#custom-chatapp-loader span:nth-child(2){
  animation: bounce 1s ease-in-out 0.33s infinite;
}
#custom-chatapp-loader span:nth-child(3){
  animation: bounce 1s ease-in-out 0.66s infinite;
}
.custom-chatapp-chat-header #custom-chatapp-minimize-button {
  position: relative;
}
.custom-chatapp-user-time {
  font-size: 10px;
  color: #6970a3;
  text-align: right;
  font-weight: 400;
  margin-bottom: 5px;
}
.custom-chatapp-bot-time {
  font-size: 10px;
  color: #6970a3;
  text-align: left;
  font-weight: 400;
}
.custom-chatapp-mr-3 {
  margin-right: 5px;
}
@keyframes bounce{
  0%, 75%, 100%{
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25%{
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@media (max-width: 767px) {
  .custom-chatapp-chat, .start-group {
      width: 330px;
  }
}