body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.chatboxform{
  display: flex;
}
.maxmize-image{
  margin-left: 184;
  padding-top: 17px;
}

form.lead-form {
  width: calc(100% - 24px);
  margin-top: 12px;
  border: 1px solid #ededed;
  height: 34px;
  padding: 0 12px;
  color: rgb(105, 112, 122);
  outline: none !important;
  padding: 5px 15px;
  height: 357px;
  overflow: hidden auto;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  max-height: 357px;
  min-height: 160px;
  flex: 0 1 auto;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb
{
	background-color: #a2a2a2;
	border: 2px solid #a2a2a2;
}
.chat-container {
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}

.chat-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}

.submit-button {
  width: 100%;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.heading{
    text-align: center;
    color: black;
}

form.lead-form {
  color: rgb(105, 112, 122);
  background: white;
  border-radius: 10px;
  padding: 25px;
  margin-top: -20px;
  max-height: 350px;
  overflow-y: auto;
  width: calc(100% - 50px);
}

form.lead-form label {
  display: block;
  margin-top: 10px;
  width: 100%;
  font-size: 15px;
  text-align: left;
  margin-bottom: 15px;
  font-weight: 400;
}

.btn_lead_form {
  width: auto;
  border: none;
  color: #ffffff;
  outline: none !important;
  background: rgb(254, 91, 52);
  display: inline-block;
  font-size: 15px;
  padding: 10px;
  min-width: 120px;
}
.custom-chatapp-form-group {
  display: block;
  margin-bottom: 15px;
}
.custom-chatapp-form-group .custom-chatapp-checkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 25px;
}

.custom-chatapp-form-group .custom-chatapp-label {
  position: relative;
  cursor: pointer;
}

.custom-chatapp-form-group .custom-chatapp-label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #e6e6e6;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -5px;
}

.custom-chatapp-form-group .custom-chatapp-checkbox:checked + .custom-chatapp-label:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 6px;
  height: 14px;
  border: solid #aaaaaa;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-chatapp-text-red{
  color:red;
}
.custom-chatapp-m-0{
  margin: 0 !important;
}
.custom-chatapp-custom-file-sec {
  text-align: left;
}
.custom-chatapp-note {
  font-size: 10px;
  font-weight: 400;
  margin-top: 2px;
}
.custom-chatapp-mt-2 {
  margin-top: 10px;
}
.custom-chatapp-agent-handoff-success-icon {
  color: green;
}
.custom-chatapp-agent-handoff-success-msg {
  color: #1c293b;
}
.custom-chatapp-agent-handoff-success-icon svg {
  height: 100px;
  width: 100px;
}
.custom-chatapp-agent-handoff-success {
  min-height: 400px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.custom-chatapp-agent-handoff-success-desc {
  font-size: 16px;
  font-weight: 400;
}
.custom-chatapp-agent-handoff-iframe {
  min-height: 400px;
}
.custom-chatapp-copy-link {
  width: 35px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 10000;
}
.custom-chatapp-copy-link.copied {
  color: #1c293b !important;
}
.custom-chatapp-talk-agent-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.custom-chatapp-talk-agent, .custom-chatapp-thumbs {
  padding: 0px 5px;
  border-radius: 30px;
  font-size: 20px;
  float: right;
  position: relative;
  right: 15%;
  top: -15px;
  box-shadow: 0px 0px 1px 1px;
  background: #fff;
}
.custom-chatapp-talk-agent-sec svg {
  width: 18px;
  height: 18px;
}
.custom-chatapp-agent-text-link {
  color: blue;
  cursor: pointer;
}
.custom-chatapp-agent-text-button {
  background: #fff;
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
}
.custom-chatapp-thumbs svg {
  height: 15px;
  width: 15px;
}